import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4><a parentName="h4" {...{
        "href": "#"
      }}><em parentName="a">{`home`}</em></a>{` / `}<a parentName="h4" {...{
        "href": "/resume"
      }}>{`resume`}</a>{` / `}<a parentName="h4" {...{
        "href": "/projects"
      }}>{`projects`}</a></h4>
    <hr></hr>
    <h1>{`>_ samroy.io`}</h1>
    <p><em parentName="p">{`Hi my name is Sam Roy and I built this website to showcase my projects and cv. You've probably noticed
by now how basic this website is. I like it that way.`}</em></p>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.linkedin.com/in/samroy92/"
        }}>{`linkedin`}</a>{` | `}<a parentName="em" {...{
          "href": "https://twitter.com/SamRoy92"
        }}>{`twitter`}</a>{` | `}<a parentName="em" {...{
          "href": "mailto:samroy92@live.com"
        }}>{`email`}</a>{` | `}<a parentName="em" {...{
          "href": "mailto:samuelmarkroy92@gmail.com"
        }}>{`gmail`}</a></em></p>
    <h3>{`Development Stats`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Project`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Branch`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Status`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Commits`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`samroyio-website`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`master`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://circleci.com/gh/samroy92/samroyio-website/tree/master"
            }}><img parentName="a" {...{
                "src": "https://circleci.com/gh/samroy92/samroyio-website/tree/master.svg?style=svg",
                "alt": "CircleCI"
              }}></img></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://GitHub.com/samroy92/samroyio-website/commit/"
            }}><img parentName="a" {...{
                "src": "https://badgen.net/github/commits/samroy92/samroyio-website",
                "alt": "GitHub commits"
              }}></img></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`samroyio-infrastructure`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`master`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://circleci.com/gh/samroy92/samroyio-infrastructure/tree/master"
            }}><img parentName="a" {...{
                "src": "https://circleci.com/gh/samroy92/samroyio-infrastructure/tree/master.svg?style=svg",
                "alt": "CircleCI"
              }}></img></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://GitHub.com/samroy92/samroyio-infrastructure/commit/"
            }}><img parentName="a" {...{
                "src": "https://badgen.net/github/commits/samroy92/samroyio-infrastructure",
                "alt": "GitHub commits"
              }}></img></a></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Project Directory Structure`}</h3>
    <pre><code parentName="pre" {...{}}>{`samroyio-website
│   .gitignore
│   gatsby-browser.ts
│   gatsby-config.ts
│   package-lock.json
│   package.json
│   README.md
│   tsconfig.json
├───.circleci
│       config.yml
└───src
    ├───components
    │       header.tsx
    │       layout.css
    │       layout.tsx
    ├───images
    │       icon.png
    └───pages
        │   404.mdx
        │   index.mdx
        │   projects.mdx
        │   resume.mdx
        │   test.mdx
        └───examples
                gatsby-404.tsx
                index-old.tsx
                pagetest.tsx
                testblog.tsx
`}</code></pre>
    <h3>{`Build`}</h3>
    <pre><code parentName="pre" {...{}}>{`  build:
    working_directory: ~/repo
    docker:
      - image: circleci/node:17-browsers
    steps:
      - checkout
      - restore_cache:
          keys:
          - v1-dependencies-{{ checksum "package-lock.json" }}
          - v1-dependencies-
      - run:
          name: Install dependencies
          command: npm install
      - save_cache:
          key: v1-dependencies-{{ checksum "package-lock.json" }}
          paths:
            - node_modules
      - run:
          name: Build
          command: |
            if [ $CIRCLE_BRANCH = 'master' ]; then
              npm run build
            fi
      - persist_to_workspace:
          root: .
          paths:
            - .
`}</code></pre>
    <h3>{`Deploy`}</h3>
    <pre><code parentName="pre" {...{}}>{`  deploy:
    working_directory: ~/repo
    docker:
      - image: innovatorjapan/awscli:latest
    steps:
      - attach_workspace:
          at: .
      - run:
          name: Deploy
          command: |
            if [ $CIRCLE_BRANCH = 'master' ]; then
              aws s3 sync public s3://samroy.io --delete --exact-timestamps;
            fi
`}</code></pre>
    <h5><a parentName="h5" {...{
        "href": "#top"
      }}>{`top of page`}</a></h5>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      